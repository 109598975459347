import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['indigenousStatus', 'nameOfFirstNation', 'race', 'tribe', 'additionalRace']

  connect() {
    this.setInitialVisibility();
    this.onIndigenousStatusChange();
    this.onRaceChange();
  }

  setInitialVisibility() {
    this.setTribeVisibility();
  }

  onRaceChange(){
    this.setTribeVisibility();
  }

  setTribeVisibility() {
    if (!this.raceTarget) return;

    const selectedRaceOption = this.raceTarget.selectedOptions[0];
    const raceType = selectedRaceOption.getAttribute('data-race-type');
    const isNativeAmericanRace = raceType === 'Native American';

    let isNativeAmericanAdditionalRace = false;
    if (this.hasAdditionalRaceTarget) {
      const selectedAdditionalRaceOption = this.additionalRaceTarget.selectedOptions[0];
      const additionalRaceType = selectedAdditionalRaceOption.getAttribute('data-race-type');
      isNativeAmericanAdditionalRace = additionalRaceType === 'Native American';
    }

    const showTribeInput  = isNativeAmericanRace || isNativeAmericanAdditionalRace

    const label = this.tribeTarget.previousElementSibling;

    if (showTribeInput) {
      this.tribeTarget.style.display = 'block';
      label.style.display = 'block';
    } else {
      this.tribeTarget.style.display = 'none';
      label.style.display = 'none';
      this.clearTribeTarget();
    }
  }

  onIndigenousStatusChange() {
    this.setNameOfFirstNationVisibility();
  }

  setNameOfFirstNationVisibility()
  {
    if (!this.hasIndigenousStatusTarget) return;
    const indigenousStatus = this.indigenousStatusTarget.checked;
    const label = this.nameOfFirstNationTarget.previousElementSibling;
    
    if (indigenousStatus) {
        this.nameOfFirstNationTarget.style.display = 'block';
        label.style.display = 'block';
      } else {
        this.nameOfFirstNationTarget.style.display = 'none';
        label.style.display = 'none';
        this.clearNameOfFirstNationTarget();
    }
  }

  clearNameOfFirstNationTarget()
  {
    this.nameOfFirstNationTarget.value = '';
  }

  clearTribeTarget(){
    this.tribeTarget.value = '';
  }
}