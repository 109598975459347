import { Controller } from 'stimulus';
import visibilityHandler from '../shared/visibility_handler';

const MARITAL_STATUS_WITH_SPOUSE = ['Married', 'Civil Union', 'Common Law', 'Widowed', 'Separated'];

export default class extends Controller {

  static targets = ['maritalStatus'];

  connect() {
    this.onMaritalStatusChange();
  }

  onMaritalStatusChange() {
    const spouseFields = document.querySelectorAll('.js-decedent-spouse-fields');
    const isDeceased = document.querySelector('.is-deceased input');

    if (MARITAL_STATUS_WITH_SPOUSE.includes(this.maritalStatusTarget.value)) {
      spouseFields.forEach(target => visibilityHandler.show(target));
    } else {
      spouseFields.forEach(target => visibilityHandler.hide(target));
    }

    if (this.maritalStatusTarget.value === 'Widowed') {
      isDeceased.value = 'true';
    } else {
      isDeceased.value = 'false';
    }
  }
}
